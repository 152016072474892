import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import HeadingLevel from '~/mixins/HeadingLevel'
import ProductBoxMixin from '~/mixins/ProductBoxMixin'
import ScrollToEventMixin from '~/mixins/ScrollToEventMixin'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  mixins: [HeadingLevel, ScrollToEventMixin, ProductBoxMixin],
  components: {
    AppImage,
    AppTitle,
  },
  mounted() {
    this.$on('scrolledTo', () => {
      this.$eventBus.$emit(eventDefinitions.PRODUCT.IMPRESSION, {
        product: this.product,
      })
    })
  },
  methods: {
    clicked() {
      this.$eventBus.$emit(eventDefinitions.PRODUCT.CLICK, { product: this.product })
    },
  },
}
