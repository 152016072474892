import AppTitle from '@theme/components/atom/AppTitle'
import Loader from '@theme/components/utils/Loader'
import NewsletterForm from '@theme/components/shop/NewsletterForm'
import ProductTeaserLine from '@theme/components/product/ProductTeaserLine'
import TextHighlight from 'vue-text-highlight'

export default {
  components: {
    AppTitle,
    NewsletterForm,
    ProductTeaserLine,
    TextHighlight,
    Loader,
  },
}
